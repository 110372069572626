/**
 * @fero
 */

export const GLOBAL_DATA = {
    PRODUCTS: 'products',
    CONTRACT_PRODUCTS: 'contractProducts',
    PRODUCT_FILTERS: 'productFilters',
    PRODUCT_DETAILS: 'productDetails',
    PRODUCT_SALES_HISTORY: 'productSalesHistory',
    MANUFACTURERS: 'manufacturers',
    INVOICES: 'invoices',
    INVOICE_CLASSES: 'invoiceClasses',
    INVENTORY_CLASSES: 'stockClasses',
    CATEGORIES: 'categories',
    CART_CURRENT: 'cartCurrent',
    CART_CUSTOMER_DETAILS: 'cartCustomerDetails',
    PREDICTION_METHODS: 'predictMethods',
    DISCOUNT_RULES: 'discountRules',
    DISCOUNT_TREE: 'discountTree',
    TASKS: 'tasks',
    COUNTRIES: 'countries',
    CUSTOMERS: 'customers',
    CUSTOMER_LIST: 'customerList',
    EMPLOYEES: 'employees',
    SUPERVISORS: 'supervisors',
    DEPARTMENTS: 'departments',
    DASHBOARD_ITEMS: 'dashboardItems',
    CUSTOMER_TYPES: 'customerTypes',
    CUSTOMER_GROUPS: 'customerGroups',
    CHANNELS: 'channels',
    CATEGORY_TREE: 'categoryTree',
    CATEGORY_DETAILS: 'categoryDetails',
    DEFAULT_CUSTOMERS: 'defaultCustomers',
    CUSTOMER_DETAILS: 'customerDetails',
    CUSTOMER_DETAILS_ADDRESSES: 'customerDetailsAddresses',
    CUSTOMER_DETAILS_USERS: 'customerDetailsUsers',
    ACCOUNT_INFO: 'userInfo',
    ACCOUNT_ADDRESSES: 'userAddresses',
    COMPANY_ADDRESSES: 'companyAddresses',
    USERS: 'users',
    USER_DETAILS: 'userDetails',
    USER_DETAILS_ADDRESSES: 'userDetailsAddresses',
    ORDERS: 'orders',
    ORDER_LINES: 'orderLines',
    ORDER_DETAILS: 'orderDetails',
    QUOTATIONS: 'quotations',
    QUOTATION_DETAILS: 'quotationDetails',
    QUOTATION_STATES: 'quotationStates',
    QUOTATION_DIVISIBILITY_LEVELS: 'quotationDivisibility',
    REQUESTS: 'requests',
    REQUEST_DETAILS: 'requestDetails',
    REQUEST_STATES: 'requestStates',
    ORDER_STATES: 'orderStates',
    ORDER_LINE_STATES: 'orderLineStates',
    UPLOAD_FILES: 'uploadFiles',
    NEWS: 'news',
    CATEGORY_NEWS: 'categoryNews',
    SHOPPING_LISTS: 'shoppingLists',
    PARAMETERS: 'parameters',
    PRODUCT_RELATIVES: 'productRelatives',
    REPORTS: 'reports',
    LANGUAGES: 'languages',
    STOCKS: 'stocks',
    CURRENCIES: 'currencies',
    FORMS: 'forms',
    FORM_DETAILS: 'formDetails',
    BACKUP_STATUS: 'backupStatus',
    TRANSFER_TYPES: 'transferTypes',
    HISTORY: 'history',
    ANALYSIS_TYPES: 'analyses',
    VEHICLES: 'vehicles',
    EVENT_TYPES: 'eventTypes',
    EVENTS: 'events',
    ALERT_TYPES: 'alertTypes',
    ALERTS: 'alerts',
    
    //error is handled by data provider and sets there an error that occurs while communication
    ERROR: 'error',
    //functions of dataProvider that are provided for data handling
    RELOAD_DATA: 'reloadData',
    FETCH_HANDLER: 'fetchHandler',
    FETCH_DATA: 'fetchData',
    FETCH_BY_DATA_DEF: 'fetchDataByDataDef',
    SET_DATA: 'setData',
};